// import Home from './pages/Home';
import './App.css';
import Routers from './Routers/Routers';

function App() {
  return (
    <div className="App">
      <Routers />
    </div>
  );
}

export default App;
