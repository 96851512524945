import { Grid, Typography, Button } from '@mui/material';
import Logo from '../../assets/Images/Logo With Name.png';

import HomeIcon from '@mui/icons-material/Home';

const LandingPage = () => {
    return (
        <Grid 
            sx={{ 
                flexGrow: 1,
                backgroundImage: "linear-gradient(to right, #c4fff9, #0353a4)"
                // background: `url(${Logo}) no-repeat left top, linear-gradient(to right, #c4fff9, #0353a4)`
            }} 
            container 
            spacing={2} 
            mt={1} 
            height="90vh" 
            alignItems="center"
        >
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid></Grid>
                    <Grid item justifyContent="center" alignItems="center" textAlign="center">
                        <img src={Logo} alt="flatklicker logo"/>
                        <Typography variant='h3'>Stand By!</Typography>
                        <Typography variant='h5' mb={2}>We are busy working on it.</Typography>
                        <Button variant="contained" href="/" sx={{ color: "black", backgroundColor: "#FFF" }} endIcon={<HomeIcon />} >Back To Home</Button>
                    </Grid>
                    <Grid></Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LandingPage;