import LandingPage from "../LandingPage";

const Register = () => {
    return (
        <div>
            <LandingPage />
        </div>
    );
}

export default Register;