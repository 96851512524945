/* =====[Styles]===== */
// import styles from "./home.module.css";
/* ================= */

/* =====[MUI]===== */
import { Grid, Typography, Card, CardContent, Button, Box, Alert, TextField } from "@mui/material";
import { FormControl, InputAdornment, Autocomplete } from "@mui/material";
/* =============== */

import axios from "axios";

/* =====[REACT]===== */
import { useState, useEffect } from "react";
/* ================= */

/* =====[ICONS]===== */
import SearchIcon from '@mui/icons-material/Search';
/* ================= */

let baseURL = process.env.REACT_APP_URL;

const Home = () => {
    const [data, setData] = useState([]);
    const [jobDetails, setJobDetails] = useState();
    const [searchValue, setSearchValue] = useState();
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationDetails, setLocationDetails] = useState([]);
    const [options, setOptions] = useState([]);
    const [countriesWithJobs, setCountriesWithJobs] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);


    const fetchAllJobs = () => {
        axios({
            method: "get",
            url: baseURL,
            params: {
                method: "fetchAllJobs"
            }
        }).then(function(response){
            setData(response.data);
            showJobDetails(response.data);
        }).catch(function (error) {
            console.error('Error fetching jobs:', error);
        });
    }
    
    const fetchLocationDetails = () => {
        axios({
            method: "get",
            url: baseURL,
            params: {
                method: "fetchLocationDetails"
            }
        }).then(function (response) {
            setLocationDetails(response.data);
            
            const retrievedOptions = locationDetails.map((option) => {
                const country = option.country.toUpperCase();
                return {
                    country: /[0-9]/.test(country) ? 'Other' : country,
                    ...option
                }
            });

            setOptions(retrievedOptions);
        }).catch(function (error) {
            console.error('Error fetching jobs:', error);
        });
    }

    const fetchCountriesWithJobs = () => {
        axios({
            method: "get",
            url: baseURL,
            params: {
                method: "fetchCountriesWithJobs"
            }
        }).then(function (response) {
            setCountriesWithJobs(response.data);
        }).catch(function (error) {
            console.error('Error fetching jobs:', error);
        });
    }

    const handleLocationChange = (event, newValue) => {
        setSelectedLocation(newValue);
    };

    const handleCountryChange = (event, newValue) => {
        setSelectedCountry(newValue);
    };

    useEffect(() => {
        fetchAllJobs();
    }, []);

    useEffect(() => {
        fetchLocationDetails();
    });

    useEffect(() => {
        fetchCountriesWithJobs();
    });

    const showJobDetails = (jobData) => {
        setJobDetails(
            jobData ? (
                <Grid>
                    <Box
                        m={2}
                    >
                        <Typography variant="h5" color="text.secondary">{jobData.clientName}</Typography>
                        <Typography variant="h4">{jobData.title}</Typography>
                        <Typography variant="h6">{jobData.location}</Typography>
                        {jobData.closing_date !== "0000-00-00" ? (
                            <Typography variant="body1">Closing Date: {jobData.closing_date}</Typography>
                        ) : (
                            <></>
                        )}
                        {jobData.external_link !== "" ? (
                            <Button variant="contained" href={jobData.external_link} target="_blank" >Apply</Button>
                        ) : (
                            <></>
                        )}
                    </Box>
                    <Box
                        m={2}
                    >
                        <Typography variant="h6"><u>About The Job:</u></Typography>
                        <Typography variant="body1">{jobData.description}</Typography>
                    </Box>
                    <Box
                        m={2}
                    >       
                        <Typography variant="h6"><u>Requirments:</u></Typography>
                        <Typography variant="body1">{jobData.job_requirements}</Typography>
                    </Box>
                    <Box
                        m={2}
                    >
                        <Typography variant="h6"><u>Benefits:</u></Typography>
                        {jobData.job_benefits !== null ? (
                         
                            <Typography variant="body1">{jobData.job_benefits}</Typography>
                        ) : (
                            <Typography variant="body1">Not Specified.</Typography>
                        )}
                    </Box>
                    <Box
                         m={2}
                    >
                        <Typography variant="h5"><u>Compensation:</u></Typography>
                        <Alert variant="outlined" severity="info">
                            {jobData.compensation !== null ? (
                                <>
                                    <Typography sx={{ fontSize: 20 }}>
                                        {jobData.compensation}
                                    </Typography>
                                    <Typography color="text.primary" sx={{ fontSize: 14 }}>
                                        <i>Set By Company.</i>
                                    </Typography>
                                </>
                                
                            ) : (
                                <>
                                    <Typography sx={{ fontSize: 20 }}>
                                        Compensation Not Provided.
                                    </Typography>
                                </>
                            )}
                            <Typography color="text.secondary" sx={{ fontSize: 12 }}>
                                <i>
                                    If an employer includes a salary or salary range on their job, we display it as "Set By Company". If a job has no salary data, we will display the average salary for the job role according to the market data made available to us." 
                                </i>
                            </Typography>
                        </Alert>
                    </Box>
                </Grid>
            ) : (
                <Grid>
                    <h1>No</h1>
                </Grid>
            )
        );
    }

    const searchJobs = () => {
        axios({
            method: "get",
            url: baseURL,
            params: {
                method: "searchJobs",
                searchString: searchValue,
                location: selectedLocation,
                country: selectedCountry
            }
        }).then(function (response) {
            setData(response.data);
        }).catch(function (error) {
            console.error('Error fetching jobs:', error);
        });
    } 

    return (
        <Grid container spacing={0.5} mt={1} >
            <Grid item md={12} m={2}>
                <FormControl
                    sx={{
                        width: "100%"
                    }}
                >
                    <Grid container spacing={0} direction="row" justifyContent="center">
                        <Grid item md={6}>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <TextField
                                    id="jobsearchInput"
                                    label="Find Your Dream Job"
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <SearchIcon />
                                          </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    sx={{
                                        width: "40%",
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid 
                            item 
                            md={6}
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}

                        >
                            <Autocomplete 
                                id="locations"
                                options={options.sort((a, b) => -b.country.localeCompare(a.country))}
                                groupBy={(option) => option.country}
                                getOptionLabel={(option) => option.city + ', ' + option.state_province}
                                onChange={handleLocationChange}
                                isOptionEqualToValue={(option, value) =>
                                    option.country === value.country && option.city === value.city
                                }
                                sx={{ width: 200, display: "inline-block" }}
                                renderInput={(params) => <TextField {...params} label="Location"/>}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo" 
                                options={countriesWithJobs}
                                onChange={handleCountryChange}
                                sx={{ width: 200 }}
                                renderInput={(params) => <TextField {...params} label="Country" />}
                                isOptionEqualToValue={(option, value) =>
                                    option.countryID === value.countryID && option.label === value.label
                                }
                            />
                            <Button variant="contained" sx={{ marginLeft: "5px" }} onClick={searchJobs}>Search</Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>
            <Grid 
                item
                md={4} 
                sx={{
                    textAlign: "right",
                    maxHeight: "92vh", 
                    overflow: "auto !important"
                }}
            >
                {data.map((dataObj, index) => (
                    <Grid key={index}>
                        {dataObj.title ? (
                            <Button onClick={() => showJobDetails(dataObj)}>
                                <Card sx={{ width: "300px", margin: "5px", textAlign: "left" }} variant="outlined">
                                    <CardContent>
                                        <Typography sx={{ fontSize: 12 }} color="text.secondary">{dataObj.clientName}</Typography>
                                        <Typography variant="h6" component="div">{dataObj.title}</Typography>
                                        <Typography sx={{ fontSize: 12 }} color="text.secondary"><i>
                                            {dataObj.compensation !== null ? (
                                                dataObj.compensation
                                            ) : (
                                                "N/A"
                                            )} 
                                            </i>, {dataObj.working_policy}
                                        </Typography>
                                        <Typography variant="body2">{dataObj.location}</Typography>
                                        <Box sx={{ float: "right" }}>
                                            <Button size="small" onClick={() => showJobDetails(dataObj)}>View More</Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Button>
                        ) : (
                            <Button>
                                <Card sx={{ width: "300px", margin: "5px", textAlign: "left" }} variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" component="div">Oops!</Typography>
                                        <Typography variant="body2">Seacrh criteria did not return any results.</Typography>
                                    </CardContent>
                                </Card>
                            </Button>
                        )}
                    </Grid>
                ))}
            </Grid>
            <Grid item md={8} >
                <Grid id="jobDetails">
                    {/* <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        Select a job from the list on the left to view it in more detail.
                    </Alert> */}
                    {jobDetails}
                    {/* {console.log(jobDetails)} */}
                </Grid>
            </Grid>

        </Grid>
    );
}

export default Home;