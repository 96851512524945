import LandingPage from "../LandingPage";

const Dashboard = () => {
    return (
        <div>
            <LandingPage />
        </div>
    );
}

export default Dashboard;